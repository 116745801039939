<template>
  <div class="fullscreen-viewer" @mousemove="mouseMoved">
    <MediaContainer
      autoplay
      :class="{ 'spatial-content': targetTypeData && targetTypeData.model }"
      :imagePath="tokenImagePath(collection, targetType, null)"
      :videoPath="
        targetTypeData &&
        targetTypeData.video &&
        collection &&
        targetType ?
        tokenVideoPath(collection, targetType, null) : ''
      "
      :modelPath="
        targetTypeData &&
        targetTypeData.model &&
        collection &&
        targetType ?
        tokenModelPath(collection, targetType) : ''
      "
      :viewerSettings="targetTypeData ? targetTypeData.viewerSettings : {}"
      background
      mode="fullscreen"
    />
    <div class="ui">
      <transition name="fade-slow">
        <div class="controls-container" v-if="showSettingsUI && timeSinceMove < 3">
          <div class="controls">
            <v-checkbox v-model="settings.showTitle" hide-details label="Title" />
            <v-checkbox v-model="settings.showIssuance" hide-details label="Issuance" />
            <v-checkbox v-model="settings.showArtist" hide-details label="Artist" />
          </div>
          <div class="close" @click="closeViewer">
            <img src="@/assets/icons/close.svg" alt="close">
          </div>
        </div>
      </transition>

      <div v-if="targetTypeData" class="info">
        <transition name="fade">
          <h1 v-if="settings.showTitle">{{ targetTypeData.name }}</h1>
        </transition>

        <transition name="fade">
          <h2 v-if="settings.showIssuance">{{ `#${formatIssuance(token)} of ${targetTypeData.cap}` }}</h2>
        </transition>

        <transition name="fade">
          <h2 v-if="settings.showArtist">By {{ targetTypeData.collectionGroup }}</h2>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  async mounted () {
    this.loadToken()
    this.movementCounter = setInterval(() => {
      this.timeSinceMove += 1
    }, 1000)
    window.addEventListener('keydown', this.pressEsc)
  },
  props: {
    collection: {
      type: String,
      default: null
    },
    token: {
      type: String,
      default: null
    },
    settings: {
      type: Object,
      default: () => {
        return {
          showTitle: true,
          showIssuance: true,
          showArtist: true
        }
      }
    },
    showSettingsUI: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      targetType: '',
      movementCounter: null,
      timeSinceMove: 0
    }
  },
  computed: {
    ...mapGetters([
      'targetTypeData'
    ])
  },
  methods: {
    ...mapActions([
      'getTargetTypeData'
    ]),
    async loadToken () {
      this.targetType = this.parseTokenType(this.token)
      await this.getTargetTypeData({ contract: this.collection, type: this.targetType })
    },
    mouseMoved () {
      this.timeSinceMove = 0
    },
    pressEsc (event) {
      if (event.key === 'Escape') {
        this.closeViewer()
      }
    },
    closeViewer () {
      this.$router.push(`/portfolio/${this.$route.params.collection}/${this.$route.params.token}`)
    }
  },
  watch: {
    collection: {
      handler () {
        this.loadToken()
      }
    },
    token: {
      handler () {
        this.loadToken()
      }
    },
    settings: {
      deep: true,
      handler (val) {
        this.$emit('settings-updated', val)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.movementCounter)
    window.removeEventListener('keydown', this.pressEsc)
  }
}
</script>

<style lang="scss" scoped>
.fullscreen-viewer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  ::v-deep .media-container video,
  ::v-deep .media-container img {
    height: 100vh;
    max-width: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  ::v-deep .spatial-viewer {
    border: none;
  }
  .ui {
    @include fill-parent;
    pointer-events: none;
    .controls {
      position: absolute;
      top: 0;
      left: 0;
      padding: $space-m;
      pointer-events: all;
      text-align: right;
      .v-input--checkbox {
        margin-top: 0;
      }
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: $space-m;
      pointer-events: all;
      cursor: pointer;
      img {
        width: 24px;
        width: 24px;
      }
    }
    .info {
      position: absolute;
      bottom: $space-xl;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      h1 {
        font-family: $font-title;
        font-size: $font-size-l;
      }
      h2 {
        font-family: $font-body;
        font-size: $font-size-ml;
      }
    }
  }
}
</style>
