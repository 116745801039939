var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fullscreen-viewer",on:{"mousemove":_vm.mouseMoved}},[_c('MediaContainer',{class:{ 'spatial-content': _vm.targetTypeData && _vm.targetTypeData.model },attrs:{"autoplay":"","imagePath":_vm.tokenImagePath(_vm.collection, _vm.targetType, null),"videoPath":_vm.targetTypeData &&
      _vm.targetTypeData.video &&
      _vm.collection &&
      _vm.targetType ?
      _vm.tokenVideoPath(_vm.collection, _vm.targetType, null) : '',"modelPath":_vm.targetTypeData &&
      _vm.targetTypeData.model &&
      _vm.collection &&
      _vm.targetType ?
      _vm.tokenModelPath(_vm.collection, _vm.targetType) : '',"viewerSettings":_vm.targetTypeData ? _vm.targetTypeData.viewerSettings : {},"background":"","mode":"fullscreen"}}),_c('div',{staticClass:"ui"},[_c('transition',{attrs:{"name":"fade-slow"}},[(_vm.showSettingsUI && _vm.timeSinceMove < 3)?_c('div',{staticClass:"controls-container"},[_c('div',{staticClass:"controls"},[_c('v-checkbox',{attrs:{"hide-details":"","label":"Title"},model:{value:(_vm.settings.showTitle),callback:function ($$v) {_vm.$set(_vm.settings, "showTitle", $$v)},expression:"settings.showTitle"}}),_c('v-checkbox',{attrs:{"hide-details":"","label":"Issuance"},model:{value:(_vm.settings.showIssuance),callback:function ($$v) {_vm.$set(_vm.settings, "showIssuance", $$v)},expression:"settings.showIssuance"}}),_c('v-checkbox',{attrs:{"hide-details":"","label":"Artist"},model:{value:(_vm.settings.showArtist),callback:function ($$v) {_vm.$set(_vm.settings, "showArtist", $$v)},expression:"settings.showArtist"}})],1),_c('div',{staticClass:"close",on:{"click":_vm.closeViewer}},[_c('img',{attrs:{"src":require("@/assets/icons/close.svg"),"alt":"close"}})])]):_vm._e()]),(_vm.targetTypeData)?_c('div',{staticClass:"info"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.settings.showTitle)?_c('h1',[_vm._v(_vm._s(_vm.targetTypeData.name))]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.settings.showIssuance)?_c('h2',[_vm._v(_vm._s(("#" + (_vm.formatIssuance(_vm.token)) + " of " + (_vm.targetTypeData.cap))))]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.settings.showArtist)?_c('h2',[_vm._v("By "+_vm._s(_vm.targetTypeData.collectionGroup))]):_vm._e()])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }