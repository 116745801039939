<template>
  <FullScreenViewer
    :collection="$route.params.collection"
    :token="$route.params.token"
  />
</template>

<script>
import FullScreenViewer from '@/components/FullScreenViewer'
export default {
  components: {
    FullScreenViewer
  }
}
</script>
